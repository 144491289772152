export function unsubscribe(target) {
  Object.keys(target).forEach(propertyName => {
    const property = target[propertyName];
    if (property) {
      if (typeof property.unsubscribe === 'function' && !property.closed) {
        property.unsubscribe();
      } else if (typeof property.complete === 'function' && !property.closed) {
        property.complete();
      } else if (typeof property === 'function' && typeof property.cancel === 'function') {
        property.cancel();
      }
    }
  });
}
